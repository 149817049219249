import React from 'react'
import Navbar2 from '../components/Navbar/Navbar2'
import Footer2 from '../components/Footers/Footer2'
import '../css/Pages.scss';
import phoneicon from '../assets/phoneicon2.png';
import mailicon from '../assets/mailicon2.png';
import Locationicon from '../assets/Locationicon2.png';
import Contactus from '../assets/Contactusi.png';
export default function Contact() {
    return (
        <>
            <Navbar2 />
            <div className="contact circle-shape">
                <div className="container">
                    <div className="circle circle-shape">
                        <div className="row ">
                            <div className="col-lg-6 col-xs-12 mt-3 columnone" id="columnone">
                                <h1><strong>Contact Us</strong></h1>

                                <div className="text">
                                    <img src={phoneicon} alt="phone_icon" />
                                    <p>011-66668018</p>
                                </div>
                                <div className="text">
                                    <img src={mailicon} alt="mail_icon" />
                                    <a className="maillink" href="mailto:arhamnbfc.finance@gmail.com ">arhamnbfc.finance@gmail.com</a>
                                </div>
                                <div className="text">
                                    <img src={Locationicon} alt="location_icon" />
                                    <p>483/24, Prahalad Street Ansari Road Daryaganj Delhi 110002</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 mt-3 columntwo" id="columntwo">
                                <img className='imgss' src={Contactus} alt="contactus_img" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
