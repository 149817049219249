import React, { useState } from 'react'
import './navbar.css'
import logo from '../../assets/logo.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'

import { Link, NavLink, useNavigate } from 'react-router-dom';
export default function Navbar2() {
    const [opened, setOpened] = useState(false);
    
    let navigate = useNavigate();

    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    const handleLinkClick = (sectionId) => {

        navigate('/');


        setTimeout(() => {

            window.scrollToSection(sectionId);
        }, 200);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <div className="navbar-area">
                <div className="container d-flex flex-row justify-content-between align-items-center">

                    <div className="navbar-brand">
                        <Link to={{ pathname: "/" }} onClick={scrollToTop}>
                            <img className='navbar-brand-img' width={'80%'} alt="" />
                        </Link>
                    </div>
                    <div className="navItems">
                        <li><NavLink to="/" >Home</NavLink></li>
                        <li><NavLink to="/" onClick={() => handleLinkClick('Aboutus')}>About Us</NavLink></li>
                        <li><NavLink to="/" onClick={() => handleLinkClick('Product')}>Products</NavLink></li>
                        <li><NavLink to={{ pathname: "/digital_lending" }} onClick={scrollToTop}>Digital Lending</NavLink></li>
                        <li><NavLink to={{ pathname: "/nbfccertificate" }}  onClick={scrollToTop}>Compliances</NavLink></li>
                        <li><NavLink to={{ pathname: "/contact" }} onClick={scrollToTop}>Contact Us</NavLink></li>
                    </div>
                    <div className="nav-toggler-icon" onClick={handleOpen}>
                        <GiHamburgerMenu className='hamIcon' />
                    </div>
                    <div id='customSidebar' className={opened ? "custom-sidebar show" : "custom-sidebar"}>
                        <div className="close-btn-wrapper">
                            <AiOutlineClose className='close-icon' onClick={handleClose} />
                        </div>
                        <ul className='sidebar-list'>
                            <li><NavLink to="/"  onClick={handleClose}>Home</NavLink></li>
                            <li><NavLink to="/" onClick={() => handleLinkClick('Aboutus')}>About Us</NavLink></li>
                            <li><NavLink to="/" onClick={() => handleLinkClick('Product')}>Products</NavLink></li>
                            <li><NavLink to={{ pathname: "/digital_lending" }} onClick={scrollToTop}>Digital Lending</NavLink></li>
                            <li><NavLink to={{ pathname: "/nbfccertificate" }}  onClick={scrollToTop}>Compliances</NavLink></li>
                            <li><NavLink to={"/contact"} onClick={handleClose}>Contact Us</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
