import React from 'react'
import Navbar2 from '../components/Navbar/Navbar2'
import Footer2 from '../components/Footers/Footer2'
import '../css/Pages.scss';

import Certificate from '../assets/nbfccerti.jpeg'

export default function ComplianceRegulatoryInfo() {
    return (
        <>
            <Navbar2 />
            <div className="ComplianceRegulatoryInfo circle-shape">
                <div className="container">
                    <div className="row heading">
                        <h1>Complainces  & Regulatory Information</h1>
                    </div>
                    <div className="box circle-shape">
                        <h2>NBFC Certification</h2>
                        <div className="subbox">
                            <img src={Certificate} alt="certificate" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
