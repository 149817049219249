import './navbar.css'
import logo from '../../assets/logo.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'

import { useState } from 'react';
// import { NavLink } from 'react-bootstrap';
import { Link, NavLink ,useNavigate} from 'react-router-dom';

function CustomNavbar() {
    const [opened, setOpened] = useState(false);

    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    // const Close = () => setClick(false);

    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="navbar-area">
            <div className="container d-flex flex-row justify-content-between align-items-center">

                <div className="navbar-brand">
                    <Link to={{ pathname: "/" }} onClick={scrollToTop}>
                        <img className='navbar-brand-img' width={'80%'} alt="" />
                    </Link>
                </div>
                <div className="navItems">
                    <li><NavLink to={{ pathname: "/" }} onClick={scrollToTop}>Home</NavLink></li>
                    <li><NavLink href="#" onClick={() => scrollToSection('Aboutus')}>About Us</NavLink></li>
                    <li><NavLink href="#" onClick={() => scrollToSection('Product')}>Products</NavLink></li>
                    <li><NavLink to={{ pathname: "/digital_lending" }} onClick={scrollToTop}>Digital Lending</NavLink></li>
                    <li><NavLink to={{ pathname: "/nbfccertificate" }} onClick={scrollToTop}>Compliances</NavLink></li>
                    <li><NavLink to={{ pathname: "/contact" }} onClick={handleClose}>Contact Us</NavLink></li>
                </div>
                <div className="nav-toggler-icon" onClick={handleOpen}>
                    <GiHamburgerMenu className='hamIcon' />
                </div>
                <div id='customSidebar' className={opened ? "custom-sidebar show" : "custom-sidebar"}>
                    <div className="close-btn-wrapper">
                        <AiOutlineClose className='close-icon' onClick={handleClose} />
                    </div>
                    <ul className='sidebar-list'>
                        <li><NavLink to={{ pathname: "/" }} onClick={scrollToTop}>Home</NavLink></li>
                        <li><NavLink href="#" onClick={() => scrollToSection('Aboutus')}>About Us</NavLink></li>
                        <li><NavLink href="#" onClick={() => scrollToSection('Product')}>Products</NavLink></li>
                        <li><NavLink to={{ pathname: "/digital_lending" }} onClick={scrollToTop}>Digital Lending</NavLink></li>
                        <li><NavLink to={{ pathname: "/nbfccertificate" }} onClick={scrollToTop}>Compliances</NavLink></li>
                        <li><NavLink to={{ pathname: "/contact" }} onClick={scrollToTop}>Contact Us</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CustomNavbar