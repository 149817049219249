import React from 'react'
import Navbar2 from '../components/Navbar/Navbar2'
import Footer2 from '../components/Footers/Footer2'
import '../css/Pages.scss';
import credfinLogo from '../assets/credfinLogo.webp'
import QuidLogo from '../assets/QuidLogo.png'
import KreditRupeeIcon from '../assets/KreditRupeeIcon.png'
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function SourcingPartner() {
    return (
        <>
            <Navbar2 />
            <div className="sourcingPartnerPage">
                <div className="topPage">
                    <div className="bannerPage height-auto">
                        <div className="banner-content">
                            <h1 className="centered-text">Sourcing Partners</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sourcingPartner circle-shape-one">
                <div className="container">
                    <div className="circle circle-shape-two">
                        <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' ,overflowX:'hidden'}}>
                            <div className="col-xl-4 col-md-6 col-xs-12 mt-4" style={{ overflowY:'hidden'}}>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    {" "}
                                    <div className="sectionsfirst" style={{ padding: '20px', height: '380px' }}>

                                        <div className="box" style={{ background: '#fff', padding: '20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                            <img src={credfinLogo} alt="credfin_logo" width={'100%'} />
                                        </div>
                                        <div className="text" style={{ margin: '20px 0px' }}>
                                            <h5 className='text-center' style={{ color: '#fff', fontSize: '24px' }}>Credfin Services Private Limited</h5>
                                            <button className='btn1' style={{ fontSize: '20px', color: '#fff', border: 'none', background: '#084A6c', padding: '12px 22px', cursor: 'pointer', marginTop: '20px', borderRadius: '12px', boxShdaow: "-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset" }}><a style={{ color: '#fff', textDecoration: "none" }} href="https://credfin.money/" target="_blank">Credfin →</a></button>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-xl-4 col-md-6 col-xs-12 mt-4" style={{ overflowY:'hidden'}}>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    {" "}
                                    <div className="sectionsfirst" style={{ padding: '20px', height: '380px' }}>
                                        <div className="box" style={{ background: '#fff', padding: '20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                            <img src={QuidLogo} alt="QuidLogo" width={'90%'} />
                                        </div>
                                        <div className="text" style={{ margin: '20px 0px' }}>
                                            <h5 className='text-center' style={{ color: '#fff', fontSize: '24px' }}>Quid Innovations Private Limited</h5>
                                            <button className='btn1' style={{ fontSize: '20px', color: '#fff', border: 'none', background: '#084A6c', padding: '12px 22px', cursor: 'pointer', marginTop: '20px', borderRadius: '12px', boxShdaow: "-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset" }}><a style={{ color: '#fff', textDecoration: "none" }} href="https://quid.money/" target="_blank">Quid →</a></button>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-xl-4 col-md-6 col-xs-12 mt-4" style={{ overflowY:'hidden'}}>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    {" "}
                                    <div className="sectionsfirst" style={{ padding: '20px', height: '380px' }}>
                                        <div className="box" style={{ background: '#fff', padding: '20px 25px', textAlign: 'center', borderRadius: '30px' }}>
                                            <img src={KreditRupeeIcon} alt="KreditRupeeIcon" width={'45%'} />
                                        </div>
                                        <div className="text" style={{ margin: '20px 0px' }}>
                                            <h5 className='text-center' style={{ color: '#fff', fontSize: '24px' }}>Mobavanue media private limited</h5>
                                            <button className='btn1' style={{ fontSize: '20px', color: '#fff', border: 'none', background: '#084A6c', padding: '12px 22px', cursor: 'pointer', marginTop: '20px', borderRadius: '12px', boxShdaow: "-2px -2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 3px 8px 0px rgba(255, 255, 255, 0.4) inset" }}><a style={{ color: '#fff', textDecoration: "none" }} href="https://www.kreditrupee.com" target="_blank">Kredit Rupee →</a></button>
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
