import React,{useState} from 'react'
import { Link ,useNavigate} from "react-router-dom";
import './Footer.scss';
import LogoBlack from '../../assets/logo-color-4.png'
import Logowhite from '../../assets/logo-white-2.png'
import MapImg from '../../assets/map.png'
import phoneicon from '../../assets/phoneicon.png'
import mailicon from '../../assets/mailicon.png'
import Locationicon from '../../assets/Locationicon.png'
import { companyFullname } from "../../Variables";

function Footer() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <footer className="footer-area-new">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-sm-6 col-md-6 mt-1">
                        <div className="single-footer-widgets">
                            <div className="logo">
                                <Link to={{
                                        pathname: "/",
                                    }} onClick={scrollToTop}>
                                    <img src={Logowhite} width={"40%"} alt="Logo" />
                                </Link>
                            </div>
                            <br />
                           
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-md-6 mt-1">
                        <div className="single-footer-widgets">
                            <h3>Quick Links</h3>
                            <ul className="list">
                                <li><Link href="#" onClick={() => scrollToSection('Aboutus')}>About us</Link></li>
                                <li><Link href="#" onClick={() => scrollToSection('Product')}>Product</Link></li>
                                <li><Link href="#" onClick={() => scrollToSection('features')}>Features</Link></li>
                                <li><Link to={"/contact"} onClick={scrollToTop}>Contact Us</Link></li>
                                <li><Link to={{
                                     pathname: "/sourcingPartner",
                                }} onClick={scrollToTop}>Sourcing Partner</Link></li>
                              
                               
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-md-6 mt-1">
                        <div className="single-footer-widgets">
                            <h3>Compliances</h3>
                            <ul className="list">
                                <li><Link to={"/"}>Privacy Policy</Link></li>
                                <li><Link to={"/"}>Interest Rate Policy</Link></li>
                                <li><Link to={"/"}>KYC AML Policy</Link></li>
                                <li><Link to={{
                                     pathname: "/nbfccertificate",
                                }} onClick={scrollToTop}>NBFC Certificate</Link></li>
                                <li><Link to={"/"}>Fair practice Code</Link></li>
                                <li><Link to={"/"}>Grievance Redressal</Link></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-md-6 mt-1">
                        <div className="single-footer-widgets">
                            <h3>Contact Us</h3>
                            <ul className="footer-contact-info" >
                                <li>
                                   <div className="text">
                                    <img src={phoneicon} alt="phone_icon" />
                                    <p>011-66668018</p>
                                   </div>
                                </li>
                                <li>
                                   <div className="text">
                                    <img src={mailicon} alt="mail_icon" />
                                    <a className="maillink" href="mailto:arhamnbfc.finance@gmail.com ">arhamnbfc.finance@gmail.com</a>
                                   </div>
                                </li>

                                <li>
                                    <div className="text">
                                        <img src={Locationicon} alt="location_icon" />
                                        <p>483/24, Prahalad Street Ansari Road Daryaganj Delhi 110002</p>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div className="copyright-areas">
                    <h6>© Copyright 2023 Arham Finance & Investment Services Ltd. | All Rights Reserved
                    </h6>
                </div>
            </div>
        

        </footer>
    )
}

export default Footer