import React from 'react'
import Navbar2 from '../components/Navbar/Navbar2'
import Footer2 from '../components/Footers/Footer2'
import '../css/Pages.scss';
import DigitalLendingimg from '../assets/DigitalLendingimg.png';

export default function DigitalLending() {
    return (
        <>
            <Navbar2 />
            <div className="DigitalLending circle-shape">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xs-12 mt-2 columnone" id="columnone">
                            <h1>Digital Lending</h1>
                            <div className="box">
                                <ul>
                                    <li>We're transforming lending with innovative digital solutions, prioritizing customer needs for easy credit access for both individuals and businesses.</li>
                                    <li>Efficient digital lending ensures fast approvals and minimal paperwork, empowering clients to seize timely opportunities and meet financial needs effectively.</li>
                                    <li>Download our Application to explore how our digital lending offerings can propel your business towards success in the rapidly evolving digital lending landscape.</li>
                                </ul>
                                <div className="btns">
                                    <a href="https://play.google.com/store/apps/details?id=com.credfin" target="_blank" className='googlebtn' style={{padding:'24px 10px'}}>
                                    <img width="50" height="50" src="https://img.icons8.com/fluency/144/google-play-store-new.png" alt="google-play-store-new" />
                                    </a>
                                    {/* <button className='googlebtn'>
                                        <img width="50" height="50" src="https://img.icons8.com/fluency/144/google-play-store-new.png" alt="google-play-store-new" />
                                    </button> */}
                                    {/* <button className='applebtn'>
                                        <img width="50" height="50" src="https://img.icons8.com/color/144/apple-app-store--v3.png" alt="apple-app-store--v3" />
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xs-12 mt-2 columntwo" id="columntwo">
                            <div className="subcolumn">
                            <img src={DigitalLendingimg} alt="digital_lending_image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />

        </>
    )
}
